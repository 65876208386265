import {Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Registration from './pages/member/Registration';
import Login from './pages/member/Login';
import Dashboard from './pages/member/Dashboard';
import Logout from './pages/member/Logout';
import ContentManagement from './pages/cms/ContentManagement';
// Add
import AddActivity from './pages/activity/AddActivity';
import AddZone from './pages/zone/AddZone';
import AddActivity_Gallery from './pages/activity/AddActivity_Gallery';
// Manage
import ManageMembers from './pages/member/ManageMembers';
import ManageZones from './pages/zone/ManageZones';
import ManageActivities from './pages/activity/ManageActivities';
import ManageActivities_Gallery from './pages/activity/ManageActivities_Gallery';
// Profile/Detail
import DetailMember from './pages/member/DetailMember';
import DetailZone from './pages/zone/DetailZone';
// Edit
import EditMember from './pages/member/EditMember';
import EditZone from './pages/zone/EditZone';
// Delete
import DeleteMember from './pages/member/DeleteMember';
import DeleteZone from './pages/zone/DeleteZone';

import ProtectedRoute from '../components/ProtectedRoute'; // Import the ProtectedRoute

const Main = () => {
  const project = 'Eagles of Zion';
  const slogan = "Youths' Ministry of Mount Zion Gospel Church, Inc. Nigeria.";
  const title = `${project} - ${slogan}`;

  return (
    <>
      {/* <Header project={project} /> */}
      <Routes>
        <Route path="/" element={<Home title={title} />} />
        
        {/* AUTH PAGES */}
        <Route path="/registration" element={<Registration project={project} />} />
        <Route path="/login" element={<Login project={project} />} />
        <Route path="/dashboard" element={<Dashboard project={project} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/manage-members" element={<ManageMembers />} />
        <Route path="/manage-zones" element={<ManageZones />} />
        <Route path="/manage-activities" element={<ManageActivities />} />
        <Route path="/add-activity-gallery" element={<AddActivity_Gallery />} />        
        <Route path="/manage-activities-gallery" element={<ManageActivities_Gallery />} />
        <Route path="/add-activity" element={<AddActivity />} />
        <Route path="/content-management" element={<ContentManagement />} />
        <Route path="/add-zone" element={<AddZone />} />

        {/* Profile/Detail */}
        <Route path="/member/:slug" element={<DetailMember />} />
        <Route path="/zone/:slug" element={<DetailZone />} />

        <Route path="/edit-member/:slug" 
          element={<EditMember />} // Don't protect route so members can edit theri profile
        />
        <Route 
          path="/edit-zone/:slug" 
          element={<ProtectedRoute element={EditZone} />} // Protect this route
        />

        {/* Delete */}
        <Route 
          path="/delete-member/:slug" 
          element={<ProtectedRoute element={DeleteMember} />} // Protect this route
        />
        <Route 
          path="/delete-zone/:slug" 
          element={<ProtectedRoute element={DeleteZone} />} // Protect this route
        />

        {/* DETAIL PAGES */}
        {/* <Route path="/course-category/:category_id" element={<CategoryDetail project={project} />} />
        <Route path="/course/:course_id" element={<CourseDetail project={project} />} />
        <Route path="/instructor/:instructor_id" element={<InstructorDetail project={project} />} />

        <Route path="/manage-courses" element={<ManageCourses />} />
        <Route path="/course-sample" element={<CourseSample project={project} />} /> */}
      </Routes>
      {/* <Footer project={project} slogan={slogan} /> */}
    </>
  );
}
export default Main;
