import { useParams } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Sidebar from '../../layouts/Sidebar';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Logout_Modal from '../../layouts/Logout_Modal';
import Header_Dashboard from '../../layouts/Header_Dashboard';

const DeleteMember = () => {
  const { slug } = useParams();

  // Function to handle delete action
  const handleDelete = () => {
    axios.delete(`${baseUrl}/member/${slug}/`)
      .then((response) => {
        successAlert();
      })
      .catch(error => {
        errorAlert(error);
      });
  };

  const handleDeleteConfirmation = () => {
    Swal.fire({
      title: 'Are you really sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };  

  const successAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Member deleted successfully!",
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.href = '/manage-members';
    });
  };

  const errorAlert = (error) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "An error occurred!",
      text: error.message,
      showConfirmButton: false,
      timer: 1500
    });
  };

  return (
    <>
    <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    
                    {/* Page Content Starts Here */}
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Delete Member</h1>
                            <a href="javascript:history.back()" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                        className="fas fa-arrow-left fa-sm text-white-50"></i> Back to Members</a>
                        </div>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3">
                                <h6 class="m-0 font-weight-bold">Delete a Member?</h6>
                            </div>
                            <div class="card-body">
                                <h3 className='mb-4'>Are you sure you want to delete this member?</h3>
                                <button className="btn btn-danger" onClick={handleDeleteConfirmation}>Yes, delete the member</button>
                                
                                <a href="javascript:history.back()" className="btn btn-primary mx-3"><i
                                    className="fas fa-sm text-white-50"></i> No, it's a mistake</a>
                            </div>
                        </div>
                    </div>
                    {/* Page Content Ends Here */}
                </div>
        
                <Footer_Dashboard />
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
                            
  );
};
export default DeleteMember;