import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Logout_Modal from '../../layouts/Logout_Modal';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';

const EditZone = () => {
  const { slug } = useParams();
  const [data, setData] = useState({
    name: '',
    contact_person: '',
    contact_phone: '',
    contact_address: ''
  });

  useEffect(() => {
    axios.get(`${baseUrl}/zone/${slug}/`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the zone data!", error);
      });
  }, [slug]);

  const handleChange = (e) => {
    setData({
      ...data, 
      [e.target.name]: e.target.value
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('contact_person', data.contact_person);
    formData.append('contact_phone', data.contact_phone);
    formData.append('contact_address', data.contact_address);

    axios.put(`${baseUrl}/zone/${slug}/`, formData)
      .then((response) => {
        successAlert();
      })
      .catch(error => {
        errorAlert(error);
      });
  };

  const successAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Zone updated successfully!",
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.href = '/manage-zones';
    });
  };

  const errorAlert = (error) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "An error occurred!",
      text: error.message,
      showConfirmButton: false,
      timer: 1500
    });
  };

  return (
    <>    
      <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <ContentWrapper />
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800">Edit Zone</h1>
                  <Link to="/manage-zones" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                    <i className="fas fa-arrow-left fa-sm text-white-50"></i> Back to Zones
                  </Link>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Edit Zone</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="">
                          <form className="user" onSubmit={submitForm}>
                            <div className="form-group row">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <input name="name" value={data.name} onChange={handleChange} maxLength="50" 
                                  className="form-control form-control-user" placeholder="Name of Zone" required />
                              </div>
                              <div className="col-sm-6">
                                <input name="contact_person" value={data.contact_person} onChange={handleChange} maxLength="50" 
                                  className="form-control form-control-user" placeholder="Contact Person" />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <input name="contact_phone" value={data.contact_phone} onChange={handleChange} maxLength="10" 
                                  className="form-control form-control-user" placeholder="Contact Phone" />
                              </div>
                              <div className="col-sm-6">
                                <input name="contact_address" value={data.contact_address} onChange={handleChange} maxLength="100" 
                                  className="form-control form-control-user" placeholder="Contact Address" />
                              </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-user">
                              Save Changes
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer_Dashboard />
          </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  );
};
export default EditZone;