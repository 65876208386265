import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Logout_Modal from '../../layouts/Logout_Modal';
// import { Link } from 'react-router-dom';

const DetailZone = () => {
    let { slug } = useParams();
    const [zone, setZone] = useState(null);

    useEffect(() => {
        axios.get(`${baseUrl}/zone/${slug}/`)
            .then(response => {
                setZone(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the zone!', error);
            });
    }, [slug]);

    if (!zone) {
        return <div>Loading...</div>;
    }

  return (
    <>
    <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    
                    {/* Page Content Starts Here */}
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Zone Detail</h1>
                            <a href="javascript:history.back()" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                              className="fas fa-arrow fa-sm text-white-50"></i> Back</a>
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">{zone.name} Zone</h6>
                            </div>
                            <div className="card-body">
                                <p>Contact Phone: {zone.contact_phone}</p>
                                <p>Contact Address: {zone.contact_address}</p>
                                <p>Contact Person: {zone.contact_person}</p>
                            </div>
                        </div>
                    </div>
                    {/* Page Content Ends Here */}
                </div>
        
                <Footer_Dashboard />
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  )
}
export default DetailZone;