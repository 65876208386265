import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Swal from 'sweetalert2';

const CMS_Faq = () => {
  const slug = 'frequent-questions';
  const [data, setData] = useState({
    section: '',
    title: '',
    subtitle: '',
    q1: '',
    ans1: '',
    q2: '',
    ans2: '',
    q3: '',
    ans3: '',
    q4: '',
    ans4: '',
  });

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data,
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  const handleChange = (e) => {
    setData({
    ...data,
    [e.target.name]: e.target.value
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('section', data.section);
    formData.append('title', data.title);
    formData.append('subtitle', data.subtitle);
    formData.append('q1', data.q1);
    formData.append('ans1', data.ans1);
    formData.append('q2', data.q2);
    formData.append('ans2', data.ans2);
    formData.append('q3', data.q3);
    formData.append('ans3', data.ans3);
    formData.append('q4', data.q4);
    formData.append('ans4', data.ans4);

    axios.put(`${baseUrl}/cms/${slug}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'  // Required for file uploads
      }
    })
      .then((response) => {
        successAlert();
      })
      .catch(error => {
        errorAlert(error);
      });
  };

  const successAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Content updated successfully!",
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.href = '/content-management';
    });
  };

  const errorAlert = (error) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "An error occurred!",
      text: error.message,
      showConfirmButton: false,
      timer: 1500
    });
  };

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-header bg-success text-white py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold">{data.section} (Content Management)</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="">
                <form className="user" onSubmit={submitForm} encType="multipart/form-data">

                  <div className="form-group row">
                    <div className="col-12">
                      <h4 className="small font-weight-bold">Title of Section</h4>
                      <input name="title" value={data.title} onChange={handleChange} maxLength="255"
                        className="form-control" /><br />
                    </div>
                    <div className="col-12 mb-3 mb-sm-0">
                      <h4 className="small font-weight-bold">Subtitle</h4>
                      <input name="subtitle" value={data.subtitle} onChange={handleChange} maxLength="255"
                        className="form-control" /><br />
                    </div>
                    <div className="col-12 mb-3 pt-3 mb-sm-0" style={{background: '#ddd'}}>
                      <h4 className="small font-weight-bold">Question 1</h4>
                      <input name="q1" value={data.q1} onChange={handleChange} maxLength="255"
                        className="form-control" /><br />
                    </div>
                    <div className="col-12" style={{background: '#ddd'}}>
                      <h4 className="small font-weight-bold">Answer to Question 1</h4>
                      <textarea name="ans1" value={data.ans1} onChange={handleChange}
                        className="form-control" rows={3}></textarea><br />
                    </div>
                    <div className="col-12 mb-3 mb-sm-0 pt-3">
                      <h4 className="small font-weight-bold">Question 2</h4>
                      <input name="q2" value={data.q2} onChange={handleChange} maxLength="255"
                        className="form-control" /><br />
                    </div>
                    <div className="col-12">
                      <h4 className="small font-weight-bold">Answer to Question 2</h4>
                      <textarea name="ans2" value={data.ans2} onChange={handleChange}
                        className="form-control" rows={3}></textarea><br />
                    </div>
                    <div className="col-12 mb-3 mb-sm-0 pt-3" style={{background: '#ddd'}}>
                      <h4 className="small font-weight-bold">Question 3</h4>
                      <input name="q3" value={data.q3} onChange={handleChange} maxLength="255"
                        className="form-control" /><br />
                    </div>
                    <div className="col-12" style={{background: '#ddd'}}>
                      <h4 className="small font-weight-bold">Answer to Question 3</h4>
                      <textarea name="ans3" value={data.ans3} onChange={handleChange}
                        className="form-control" rows={3}></textarea><br />
                    </div>
                    <div className="col-12 mb-3 mb-sm-0 pt-3">
                      <h4 className="small font-weight-bold">Question 4</h4>
                      <input name="q4" value={data.q4} onChange={handleChange} maxLength="255"
                        className="form-control" /><br />
                    </div>
                    <div className="col-12">
                      <h4 className="small font-weight-bold">Answer to Question 4</h4>
                      <textarea name="ans4" value={data.ans4} onChange={handleChange}
                        className="form-control" rows={3}></textarea><br />
                    </div>
                    
                  </div>

                  <button type="submit" className="btn btn-primary btn-user">
                    Save Draft
                  </button>
                  <Link to="" className="btn btn-secondary btn-user mx-2">Preview</Link>

                  <button type="" className="btn btn-success btn-user float-right">
                    PUBLISH AS FINAL
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CMS_Faq;