import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';

const UpcomingEvent = () => {
  const slug = 'upcoming-event';
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  return (
    <>
    <section className="welcome_eagles grid purple" id="UpcomingEvent">
      <div className="about__data pl-2">
          <h2 className="section__title">{data.title}</h2>
          <h1 className="office subtitle">{data.subtitle}</h1><br />

          <p>{data.content} ... Read more</p>
          <br />
          <p><Link to="/registration" className="button button__header">Register Now!</Link></p>

          {/* <p>The conference will feature keynote speakers who are passionate about God, youth ministry, interactive workshops on personal growth and spiritual development, and engaging breakout sessions tailored to various interests and age groups. Attendees will have the opportunity to participate in group discussions, testimonies, creative activities (Sports/Games), and community service projects (RURAL RUGED).
          With vibrant worship sessions led by talented music teams, reflective prayer times and Counseling, 
          this year's Youth Conference promises to be a memorable and uplifting experience. 
          Come ready to connect with peers, deepen your faith, and leave inspired to make a positive impact 
          in your community.</p><br />


          <p>We look forward to seeing you there and embarking on this faith-filled camp journey together!</p> */}

          {/* <Public_Activities /> */}
      </div>
    
      <div className="" id="mzgc">
        <br />
        <div className="about__thumb">
          <img src={data.image} className="about__thumb-img full-image" alt="" />
        </div>
        <br />
      </div>
      
    </section>
    </>
  )
}

export default UpcomingEvent
