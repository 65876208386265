import Header from '../__public/__layouts/Header';
import Nav from '../__public/__layouts/Nav';
import Banner from '../__public/__layouts/Banner';
import UpcomingEvent from '../__public/__sections/UpcomingEvent';
import ChurchPresident_Welcome from '../__public/__sections/ChurchPresident_Welcome';
import EaglesPresident_Welcome from '../__public/__sections/EaglesPresident_Welcome';
import EaglesExcos from '../__public/__sections/EaglesExcos';
import Activities from '../__public/__sections/Activities';
import Faqs from '../__public/__sections/Faqs';
import Footer from '../__public/__layouts/Footer';
// import Zones from '../__public/__sections/Zones';

const Home = () => {
  return (
    <>
      <Header />
      <Nav />

      <Banner />      
      <UpcomingEvent />

      <ChurchPresident_Welcome />
      <EaglesPresident_Welcome />
      <Activities />
      {/* <Zones />  IS WORKING WELL. USE ON A SEPARATE PAGE */}
      <EaglesExcos />
      
      <Faqs />
      <Footer />

      {/* <Zones /> */}
  </>
  );
}

export default Home;