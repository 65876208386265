import { useState, useEffect } from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Logout_Modal from '../../layouts/Logout_Modal';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';

const AddActivity_Gallery = () => {
    const [data, setData] = useState({
        'name': '',
        'image_gallery': null,  // Ensure this is initialized as null for the file
        'activity': ''          // To store selected activity ID
    });
    const [activities, setActivities] = useState([]);  // To store available activities for the dropdown

    // Fetch activities for the dropdown
    useEffect(() => {
        axios.get(`${baseUrl}/activity/`)
            .then(response => {
                setActivities(response.data);  // Set activities for dropdown
            })
            .catch(error => {
                console.error("Error fetching activities:", error);
            });
    }, []);

    // Change element value
    const handleChange = (e) => {
        if (e.target.name === 'image_gallery') {
            setData({
                ...data, 
                [e.target.name]: e.target.files[0]  // Use files[0] for the file input
            });
        } else {
            setData({
                ...data, 
                [e.target.name]: e.target.value
            });
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        // Append data to FormData, including the file and selected activity
        formData.append('name', data.name);
        formData.append('image', data.image_gallery);  // Make sure this matches your API field name
        formData.append('activity', data.activity);    // Append selected activity ID

        axios.post(`${baseUrl}/activity-gallery/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'  // Required for file uploads
            }
        })
        .then((response) => {
            setData({
                'name': '',
                'image_gallery': null,
                'activity': ''
            });
            successAlert();
        })
        .catch(error => {
            errorAlert(error);
        });
    };

    const successAlert = () => {
        Swal.fire({
            position: "center",
            icon: "success",
            title: "Gallery saved successfully!",
            showConfirmButton: false,
            timer: 1500
        });
    };

    const errorAlert = (error) => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "An error occurred!",
            text: error.message,
            showConfirmButton: false,
            timer: 1500
        });
    };

    return (
    <>    
      <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    
                    {/* Page Content Starts Here */}
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Sub-Activity Gallery</h1>
                            <Link to="/manage-activities-gallery" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                <i className="fas fa-plus fa-sm text-white-50"></i> Manage Sub-Gallery
                            </Link>
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 bg-info">
                                <h6 className="m-0 font-weight-bold text-white">Add Sub-Activity Gallery</h6>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-12">
                                    <div className="">
                                        <form className="user" onSubmit={submitForm} encType="multipart/form-data">
                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">Name of Activity</h4>
                                                    <input 
                                                        name="name" 
                                                        value={data.name} 
                                                        onChange={handleChange} 
                                                        maxLength="50" 
                                                        className="form-control" 
                                                        placeholder="E.g. Photo of GOTE 2015 at Ikot Abasi Obori in Calabar" 
                                                        required 
                                                    />
                                                </div>
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">Select Activity Category</h4>
                                                    <select 
                                                        name="activity" 
                                                        value={data.activity} 
                                                        onChange={handleChange} 
                                                        className="form-control" 
                                                        required
                                                    >
                                                        <option value="">Select Activity</option>
                                                        {activities.map(activity => (
                                                            <option key={activity.id} value={activity.id}>
                                                                {activity.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-6 my-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">Upload Image</h4>
                                                    <input 
                                                        type="file" 
                                                        name="image_gallery" 
                                                        onChange={handleChange} 
                                                        className="" 
                                                        accept="image/*" 
                                                        required 
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <h4 className="small font-weight-bold">&nbsp;</h4>
                                                    <button type="submit" className="btn btn-success btn-user">
                                                        Save Gallery
                                                    </button>
                                                </div>
                                            </div>                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    {/* Page Content Ends Here */}
                </div>
        
                <Footer_Dashboard />
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  );
}

export default AddActivity_Gallery;
