import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UilApps, UilMultiply } from '@iconscout/react-unicons';
import eagles_logo from '../../../assets/img/logos/eagles_logo_removebg.png';

const Nav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const menu = document.querySelector('#menu-bar');
        const navbar = document.querySelector('.navbar');
        const header = document.querySelector('header');
        const navLogo = document.querySelector('.nav__logo'); // Get the nav__logo element

        const handleMenuClick = () => {
            setIsMenuOpen(!isMenuOpen);
            menu.classList.toggle('times');
            navbar.classList.toggle('nav-toggle');
        };

        const handleScroll = () => {
            if (window.scrollY > 0) {
                header.style.background = '#fff';
                header.style.color = '#f00'; //here
                navbar.classList.add('red-links');
                navLogo.classList.add('red-logo'); // Add class to nav__logo
            } else {
                header.style.background = 'transparent';
                navbar.classList.remove('red-links');
                navLogo.classList.remove('red-logo'); // Remove class from nav__logo
            }
        };

        menu.addEventListener('click', handleMenuClick);
        window.addEventListener('scroll', handleScroll);

        return () => {
            menu.removeEventListener('click', handleMenuClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMenuOpen]);

    return (
        <>
        <header>
            <Link to="/" className="nav__logo"><img src={eagles_logo} alt="logo-light" />Eagles of Zion</Link> 
            
            <div id="menu-bar">
                {isMenuOpen ? <UilMultiply /> : <UilApps />}
            </div>

            <nav className="navbar">                
                <Link to="/media" className="">About Us</Link>
                {/* ABOUT US - Who we are, What we do, etc.
                    THE CHURCH 
                    ZONES - Calabar, Uyo, Lagos, PH, etc.
                    MEDIA - Team, Information, Resources, etc.
                    GALLERY - Photos, Videos, etc.
                    EXECUTIVES OF EAGLES OF ZION
                */}

                <Link to="/programmes" className="">Zones</Link>
                {/* GOTE.
                    ALLICANDO.
                    RURAL RUGGED.
                    FIRE NIGHT.
                */}
                
                <Link to="/contact" className="">Contact</Link>
                <Link to="/registration" className="button button__header">Register/Login</Link>
            </nav>
        </header>
        </>
    );
};

export default Nav;