import {useState, useEffect} from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Logout_Modal from '../../layouts/Logout_Modal';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';

const AddActivity = () => {
    const[data, setData] = useState({
        'name': '',
        'description': '',
        'image_gallery': ''
    });
    // change element value
    const handleChange = (e) => {
        setData({
            ...data, 
            [e.target.name]:e.target.value
        });
    }

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('name', data.name)
        formData.append('description', data.description)
        formData.append('image_gallery', data.image_gallery)

        axios.post(`${baseUrl}/activity/`, formData)
        .then((response) => {
            setData({
                'name': '',
                'description': '',
                'image_gallery': '', 
                // 'role': ''
            });
            successAlert();
        })
        .catch(error => {
            setData({
                // 'status': 'error'
            });
            errorAlert(error);
        });
    }

    // const loggedInStatus = localStorage.getItem('loggedInStatus')
    // if (loggedInStatus == 'true'){
    //     window.location.href = '/dashboard';
    // }

    const successAlert = (response) => {
        return(
            Swal.fire({            
                // position: "bottom-end",
                position: "center",
                icon: "success",
                title: "Activity saved successfully!",
                showConfirmButton: false,
                timer: 1500
          }).then(function() {
          })           
        )
    }
    const errorAlert = (error) => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "An error occurred!",
            text: error.message,
            showConfirmButton: false,
            timer: 1500
        });              
    }

  return (
    <>    
      <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    
                    {/* Page Content Starts Here */}
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Add an Event</h1>
                            <Link to="/manage-activities" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                              className="fas fa-plus fa-sm text-white-50"></i> Manage Events</Link>
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 bg-info">
                                <h6 className="m-0 font-weight-bold text-white">Add Event/Activity </h6>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                {/* <div className="col-lg-6 d-none d-lg-block bg-password-image"></div> */}
                                <div className="col-12">
                                    <div className="">
                                        <form className="user">
                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">Name of Activity</h4>
                                                    <input name="name" value={data.name} onChange={handleChange} maxLength="50" 
                                                        className="form-control" placeholder="Enter Event Name e.g. GOTE" required />
                                                </div>
                                                <div className="col-sm-6">
                                                    <h4 className="small font-weight-bold">Description</h4>
                                                    <input name="description" value={data.description} onChange={handleChange} maxLength="100" 
                                                    className="form-control" />
                                                </div>
                                                <div className="col-sm-6">
                                                    <h4 className="small font-weight-bold">&nbsp;</h4>
                                                    <button type="submit" onClick={submitForm} className="btn btn-success btn-user">
                                                        Save Activity
                                                    </button>
                                                </div>
                                            </div>                                            
                                        </form>
                                        {/* <hr />
                                        <div className="text-center">
                                            <a className="small" href="register.html">Create an Account!</a>
                                        </div>
                                        <div className="text-center">
                                            <a className="small" href="login.html">Already have an account? Login!</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    {/* Page Content Ends Here */}
                </div>
        
                <Footer_Dashboard />
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  )
}

export default AddActivity
