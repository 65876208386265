import { useState, useEffect } from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Sidebar from '../../layouts/Sidebar';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Logout_Modal from '../../layouts/Logout_Modal';
import { Link } from 'react-router-dom';
import Header_Dashboard from '../../layouts/Header_Dashboard';

const ManageMembers = () => {
  const [data, setData] = useState([]);
  const [cats, setCats] = useState([]); // categories

  useEffect(() => {
    axios.get(`${baseUrl}/member/`)
      .then((response) => {
        setData(response.data);
      })
      .catch(error => {
        setData([]);
      });
  }, []);

  useEffect(() => {
    axios.get(`${baseUrl}/zone`)
      .then((response) => {
        setCats(response.data);
      })
      .catch(error => {
        setCats([]);
      });
  }, []);

  return (
    <>
      <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    
                    {/* Page Content Starts Here */}
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Members</h1>
                            {/* <Link to="/add-member" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                              className="fas fa-plus fa-sm text-white-50"></i> Add Member</Link> */}
                        </div>
                        <div class="card shadow mb-4">
                            <div class="card-header py-3">
                                <h6 class="m-0 font-weight-bold">Registered Members</h6>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                  <thead>
                                    <tr>
                                        <th>Member's Name</th>
                                        <th>Phone No.</th>
                                        <th>Zone</th>
                                        <th>Email Addr.</th>
                                        <th>Manage</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {data.map((dt, index) => (

                                    <tr key={index}>                                          
                                      <td style={{ fontWeight: 'bolder'}}>
                                        <Link to={`/member/${dt.slug}`} title='View Profile'>
                                          {dt.fname} {dt.sname}
                                        </Link>
                                      </td>
                                        
                                      <td>{dt.phone}</td>
                                      <td>{cats.find(category => category.id === dt.zone)?.name || "Unknown Category"}</td>
                                      <td>{dt.email}</td>
                                      <td>                                        
                                        <Link to={`/edit-member/${dt.slug}`}><i className="fas fa-fw fa-pen text-info" title="Edit"></i> Edit</Link>
                                        <Link to={`/delete-member/${dt.slug}`}><i className="fas fa-fw fa-trash text-danger mx-3" title="Delete"></i></Link>
                                      </td>                                      
                                    </tr>

                                    ))} 
                                  </tbody>
                                </table>
                              </div>
                            </div>
                        </div>
                    </div>
                    {/* Page Content Ends Here */}
                </div>
        
                <Footer_Dashboard />
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  );
}
export default ManageMembers;