import { useState, useEffect } from 'react';
import Sidebar from "../../layouts/Sidebar";
import Header_Dashboard from '../../layouts/Header_Dashboard';
// import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Logout_Modal from '../../layouts/Logout_Modal';
import {Link} from 'react-router-dom';
import CMS_UpcomingEvent from '../../pages/cms/CMS_UpcomingEvent';
import CMS_MZGCPresidentWelcome from '../../pages/cms/CMS_MZGCPresidentWelcome';
import CMS_EaglesPresidentWelcome from '../../pages/cms/CMS_EaglesPresidentWelcome';
import CMS_Faq from './CMS_Faq';

const ContentManagement = (props) => {
  const [programmeComponent, setprogrammeComponent] = useState();

  const showUpcomingEvent = () => {
    setprogrammeComponent(<CMS_UpcomingEvent />)
  }
  const showMZGCPresidentWelcome = () => {
    setprogrammeComponent(<CMS_MZGCPresidentWelcome />)
  }
  const showEaglesPresidentWelcome = () => {
    setprogrammeComponent(<CMS_EaglesPresidentWelcome />)
  }
  const showFaq = () => {
    setprogrammeComponent(<CMS_Faq />)
  }

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('loggedInStatus');
    if (loggedInStatus !== 'true') {
      window.location.href = '/login';
    }
  }, []);

  return (
    <>
      <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar project={props.project} />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    
                    <div className="container-fluid">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Edit Page/Section Contents</h1>
                        </div>

                      <div className="row">

                        <div className="col-xl-4 col-lg-5">
                            <div className="card shadow mb-4">
                              <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold">Choose Section</h6>
                              </div>
                              <div className="card-body">

                                <Link onClick={showUpcomingEvent}>
                                  <h4 className="small font-weight-bold">Upcoming Event </h4>
                                  <div className="progress mb-4">
                                    <div className="progress-bar bg-danger" role="progressbar" style={{width: '20%'}}
                                        aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </Link>

                                <Link onClick={showMZGCPresidentWelcome}>
                                  <h4 className="small font-weight-bold">MZGC President Welcome </h4>
                                  <div className="progress mb-4">
                                    <div className="progress-bar bg-warning" role="progressbar" style={{width: '40%'}}
                                        aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </Link>

                                <Link onClick={showEaglesPresidentWelcome}>
                                  <h4 className="small font-weight-bold">Eagles President Welcome </h4>
                                    <div className="progress mb-4">
                                      <div className="progress-bar" role="progressbar" style={{width: '60%'}}
                                        aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </Link>

                                {/* <Link onClick={showEaglesExcos}>
                                  <h4 className="small font-weight-bold">National Eagles Excos </h4>
                                    <div className="progress mb-4">
                                      <div className="progress-bar bg-info" role="progressbar" style={{width: '80%'}}
                                          aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </Link> */}

                                <Link onClick={showFaq}>
                                  <h4 className="small font-weight-bold">Frequently Asked Questions </h4>
                                  <div className="progress">
                                      <div className="progress-bar bg-success" role="progressbar" style={{width: '100%'}}
                                          aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-8 col-lg-7">

                            {programmeComponent}

                              {/* <div className="card shadow mb-4">
                                  <div
                                      className="card-header bg-info text-white py-3 d-flex flex-row align-items-center justify-content-between">
                                      <h6 className="m-0 font-weight-bold">National Messages</h6>
                                  </div>
                                  <div className="card-body">
                                      <ul>
                                          <li>Recent announcement sent internally from the Eagles of Zion to all registered and verified members.
                                          </li><br />
                                          <li className="mb-0">Some older announcement sent internally from the National Youth' Fellowship of 
                                              the Church to verified members.</li>
                                      </ul>
                                  </div>
                              </div> */}
                          </div>                          
                      </div>
                  </div>

                </div>
        
                {/* <Footer_Dashboard /> */}
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  );
};

export default ContentManagement;
