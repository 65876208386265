const Footer_Dashboard = () => {
  return (
    <footer className="sticky-footer bg-white">
        <div className="container my-auto">
            <div className="copyright text-center my-auto">
                <span>Copyright &copy; Eagles 2024.</span>
            </div>
        </div>
    </footer>
  )
}
export default Footer_Dashboard