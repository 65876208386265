import { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Logout_Modal from '../../layouts/Logout_Modal';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';

const ManageActivities_Gallery = () => {
  const [data, setData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const adminStatus = localStorage.getItem('isAdmin') === 'true';
    setIsAdmin(adminStatus);
  }, []);

  useEffect(() => {
    axios.get(`${baseUrl}/activity-gallery/`)  // Use your API URL directly
      .then((response) => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching gallery data:', error);
        setData([]);
      });
  }, []);

  return (
    <>
      <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
          <Sidebar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <ContentWrapper />
              
              {/* Page Content Starts Here */}
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800">Sub-Activity Gallery</h1>
                  <Link to="/add-activity-gallery" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                    <i className="fas fa-plus fa-sm text-white-50"></i> Add Gallery
                  </Link>
                </div>

                <div className="card shadow mb-4">
                  <div className="card-header py-3 bg-info">
                    <h6 className="m-0 font-weight-bold text-white">Manage Gallery</h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                          <tr>
                            <th>Gallery</th>
                            <th>Event Category</th>
                            <th>Image</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>

                          {data.map((dt, index) => (
                            <tr key={index}>
                              <td>{dt.name}</td>
                              <td>{dt.activity}</td> {/* Displaying category name */}
                              <td>
                                <img
                                  src={dt.image}  // No need to concatenate the URL
                                  alt={dt.name}
                                  style={{ width: '100px', height: 'auto' }}
                                />
                              </td>
                              <td>
                                {isAdmin ? (
                                  <>
                                    <Link to=""><i className="fas fa-fw fa-edit text-primary mr-3" title="Edit"></i></Link>
                                    <Link to=""><i className="fas fa-fw fa-trash text-danger mr-3" title="Delete"></i></Link>
                                  </>
                                ) : (<></>)}
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* Page Content Ends Here */}
            </div>

            <Footer_Dashboard />
          </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  );
}

export default ManageActivities_Gallery;
