import {useState, useEffect} from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Sidebar from '../../layouts/Sidebar';

const Registration = (props) => {
    const[data, setData] = useState({
        'zone': '',
        'fname': '',
        'sname': '',
        'gender': '',
        'phone': '',
        'email': '',
        'password': '',        
        'location': '',
        'age_range': '', 
        'denomination': '', 
        'section': '', 
        'district': '', 
        'assembly': '',
        'mode_of_attendance': '', 
        'professional_session': '', 
        'how_did_you_hear': '',
        'image_gallery': null  // Ensure this is initialized as null for the file
        // is_nationalexco = models.BooleanField(default=False) 
        // 'role': 'Member'
    });
    const [zones, setZones] = useState([]); // categories
    
    useEffect(() => {
        document.title = `${props.project} | Member Registration`;
    });

    // change element value
    const handleChange = (e) => {
        if (e.target.name === 'image_gallery') {
            setData({
                ...data, 
                [e.target.name]: e.target.files[0]  // Use files[0] for the file input
            });
        } else {
            setData({
                ...data, 
                [e.target.name]: e.target.value
            });
        }
    };

    useEffect(() => {
        axios.get(`${baseUrl}/zone/`)
          .then((response) => {
            setZones(response.data);
          })
          .catch(error => {
            setZones([]);
          });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('zone', data.zone)
        formData.append('fname', data.fname)
        formData.append('sname', data.sname)
        formData.append('gender', data.gender)
        formData.append('phone', data.phone)
        formData.append('email', data.email)        
        formData.append('password', data.password)        
        formData.append('location', data.location)
        formData.append('age_range', data.age_range) 
        formData.append('denomination', data.denomination) 
        formData.append('section', data.section) 
        formData.append('district', data.district) 
        formData.append('assembly', data.assembly)
        formData.append('mode_of_attendance', data.mode_of_attendance) 
        formData.append('professional_session', data.professional_session) 
        formData.append('how_did_you_hear', data.how_did_you_hear)        
        // Only append the image if it's provided
        if (data.image_gallery && data.image_gallery !== 'null') {
            formData.append('image', data.image_gallery);
        }
        // formData.append('role', data.role)

        axios.post(`${baseUrl}/member/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'  // Required for file uploads
            }
        })            
        .then((response) => {
            setData({
                'zone': '',
                'fname': '',
                'sname': '',
                'gender': '',
                'phone': '',
                'email': '',
                'password': '',
                'location': '',
                'age_range': '', 
                'denomination': '', 
                'section': '', 
                'district': '', 
                'assembly': '',
                'mode_of_attendance': '', 
                'professional_session': '', 
                'how_did_you_hear': '',
                'image_gallery': null,
                // 'role': ''
            });
            console.log(response.data)
            successAlert();
        })
        .catch(error => {
            setData({
                // 'status': 'error'
            });
            errorAlert(error);
        });
    }

    const loggedInStatus = localStorage.getItem('loggedInStatus')
    if (loggedInStatus == 'true'){
        window.location.href = '/dashboard';
    }

    const successAlert = (response) => {
        return(
            Swal.fire({            
                // position: "bottom-end",
                position: "center",
                icon: "success",
                title: "Membership registration is successful!",
                showConfirmButton: false,
                timer: 1500
          }).then(function() {
          })           
        )
    }
    const errorAlert = (error) => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "An error occurred!",
            text: error.message,
            showConfirmButton: false,
            timer: 1500
        });              
    }

    return (
        <>
        <Header_Dashboard />

        <div id="page-top">
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content" style={{ background: '#ddd'}}>
              {/* <ContentWrapper /> */}
              
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between my-4">
                </div>

                <form encType="multipart/form-data">    
                    <div className="row">
                        <div className="col-xl-8 col-lg-7 m-auto">

                            <div className="card shadow mb-4">
                                <div className="card-header bg-primary text-white py-3  flex-row align-items-center justify-content-between">
                                    <p className="small float-right" style={{ color: 'white' }}>Already registered? &nbsp;
                                    <Link to="/login" className="btn btn-sm" style={{ color: 'black', background: '#FFDF00' }}>Login Here!</Link></p>
                                    
                                    <h1 className="font-weight-bold">Eagles of Zion</h1>
                                    <h5 className="m-0 font-weight-bold">Member Registration</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">                                        
                                            <div className="form-group row"> 
                                                
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">First Name</h4>
                                                <input name="fname" value={data.fname} onChange={handleChange} maxLength="40" 
                                                    className="form-control" required />
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Surname</h4>
                                                <input name="sname" value={data.sname} onChange={handleChange} maxLength="30" 
                                                    className="form-control" required />
                                            </div>  
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Gender</h4>
                                                <select className="form-control" name="gender" 
                                                    value={data.gender} onChange={handleChange}>

                                                    <option value="">- Select Gender -</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Male">Male</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Phone No.</h4>
                                                <input name="phone" value={data.phone} onChange={handleChange} maxLength="17" 
                                                    className="form-control" />
                                            </div> 

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Email Address</h4>
                                                <input name="email" type="email" value={data.email} onChange={handleChange} maxLength="60" 
                                                    className="form-control" required />
                                            </div>

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Create a Password</h4>
                                                <input name="password" type="password" value={data.password} onChange={handleChange} maxLength="100" 
                                                    className="form-control" placeholder="A secret code you can remember ..." required />
                                            </div> 

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Your Location</h4>
                                                <select className="form-control" name="location" 
                                                    value={data.location} onChange={handleChange}>

                                                    <option value="">- Select Location -</option>
                                                    <option value="Nigeria (North)">Nigeria (North)</option>
                                                    <option value="Nigeria (South)">Nigeria (South)</option>
                                                    <option value="Nigeria (East)">Nigeria (East)</option>
                                                    <option value="Nigeria (West)">Nigeria (West)</option>
                                                    <option value="Other African">Other African</option>
                                                    <option value="Rest of the world">Rest of the world</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">MZGC Member? (Denomination)</h4>
                                                <select className="form-control" name="denomination" 
                                                    value={data.denomination} onChange={handleChange}>

                                                    <option value="">- Select Denomination -</option>
                                                    <option value="Member, MZGC">Member, Mount Zion Gospel Church</option>
                                                    <option value="Non-Member, MZGC">Non-Member, MZGC</option>
                                                </select>
                                            </div> 

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Your Zone (if member of MZGC)</h4>
                                                
                                                <select name="zone" value={data.zone} onChange={handleChange} 
                                                    className="form-control" required>                                                        
                                                    <option value="">- Choose Zone -</option>
                                                    {zones.map(zone => (
                                                        <option key={zone.id} value={zone.id}>{zone.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Section</h4>
                                                <select className="form-control" name="section" 
                                                    value={data.section} onChange={handleChange}>

                                                    <option value="">- Select Section -</option>
                                                    <option value="Abak">Abak</option>
                                                    <option value="Anua">Anua</option>
                                                    <option value="Calabar">Calabar</option>
                                                    <option value="Idu">Idu</option>
                                                    <option value="Lagos">Lagos</option>
                                                    <option value="Port Harcourt">Port Harcourt</option>
                                                    <option value="Uyo">Uyo</option>
                                                </select>
                                            </div> 

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">District</h4>
                                                <select className="form-control" name="district" 
                                                    value={data.district} onChange={handleChange}>

                                                    <option value="">- Select District -</option>                                                
                                                </select>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Assembly</h4>
                                                <select className="form-control" name="assembly" 
                                                    value={data.assembly} onChange={handleChange}>

                                                    <option value="">- Select Assembly -</option>                                                
                                                </select>
                                            </div> 

                                            <div className="col-sm-12 col-md-6 mb-3">
                                            <h4 className="small font-weight-bold">Which professional session you will like to attend?</h4>
                                                <select className="form-control" name="professional_session" 
                                                    value={data.professional_session} onChange={handleChange}>

                                                    <option value="">- Select Professional Session -</option>                                                     
                                                    <option value="Agriculture">Agriculture</option> 
                                                    <option value="Business startup">Business startup</option> 
                                                    <option value="Creative design">Creative design</option>  
                                                    <option value="Digital marketing">Digital marketing</option>  
                                                    <option value="Legal">Legal</option> 
                                                    <option value="Soft-skill enhancement">Soft-skill enhancement</option>                                              
                                                </select>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Preferred mode of attendance</h4>
                                                <select className="form-control" name="mode_of_attendance" 
                                                    value={data.mode_of_attendance} onChange={handleChange}>

                                                    <option value="">- Select -</option> 
                                                    <option value="Physical">Physical</option>                                               
                                                    <option value="Virtual">Virtual</option>
                                                </select>
                                            </div>  

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Your Age Range</h4>
                                                <select className="form-control" name="age_range" 
                                                    value={data.age_range} onChange={handleChange}>

                                                    <option value="">- Select -</option>
                                                    <option value="Below 15">Below 15</option>
                                                    <option value="16-19">16-19</option>
                                                    <option value="20-30">20-30</option>
                                                    <option value="31-40">31-40</option>
                                                    <option value="Above 40">Above 40</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">How did you hear about us?</h4>
                                                <select className="form-control" name="how_did_you_hear" 
                                                    value={data.how_did_you_hear} onChange={handleChange}>

                                                    <option value="">- Select -</option>                                              
                                                    <option value="Church">Church</option>
                                                    <option value="Friend">Friend</option> 
                                                    <option value="Social media">Social media</option>
                                                    <option value="Online search">Online search</option> 
                                                    <option value="Other event">Other event</option>
                                                </select>
                                            </div> 

                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <h4 className="small font-weight-bold">Upload your photo</h4>
                                                    <input 
                                                    type="file" 
                                                    name="image_gallery" 
                                                    onChange={handleChange} 
                                                    className="" 
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <button type="submit" onClick={submitForm} className="btn btn-success btn-user btn-block">
                                                    Register
                                                </button>
                                            </div>
                                            <hr />
                                            <div className="col-sm-12 col-md-6 mb-3">                                        
                                                <p className="small" style={{ color: 'black' }}>Already registered? &nbsp;
                                                    <Link to="/login" className="btn btn-sm" style={{ color: 'black', background: '#FFDF00' }}>Login Here!</Link></p>
                                            </div> 
                                        </div>
                                      </div>
                                  </div>
                              </div>
                          </div>                          
                        </div>                        
                    </div>
                    </form> 
                </div>
                

              </div>
          </div>

          </div>
          </div>
        </>
    )
}
export default Registration;