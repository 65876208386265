import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../configs/Config';
import logo from '../../../assets/img/_Pics_Eagles_and_MZGC/MZGC Logo - Perfect sharpness.jpeg';

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Set the first FAQ to open by default
  const slug = 'frequent-questions';
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle open/close for the accordion
  };

  return (
    <>
    <section className="welcome_eagles grid purple" id="Last">
      <div className="about__data pl-2">
        <br /><br />
        <h2 className="section__title about__title">{data.title}</h2>
        <h1 className="office subtitle">{data.subtitle}</h1>

        <div className="faq-accordion">
          {/* First FAQ */}
          <div className={`faq-item ${activeIndex === 0 ? 'active' : ''}`}>
            <div className={`faq-title ${activeIndex === 0 ? 'active' : ''}`} onClick={() => toggleAccordion(0)}>
              <span>{data.q1}</span>
              <span className="toggle-icon">
                {activeIndex === 0 ? '-' : '+'}
              </span>
            </div>
            {activeIndex === 0 && (
              <div className="faq-content">{data.ans1}</div>
            )}
          </div>

          {/* Second FAQ */}
          <div className={`faq-item ${activeIndex === 1 ? 'active' : ''}`}>
            <div className={`faq-title ${activeIndex === 1 ? 'active' : ''}`} onClick={() => toggleAccordion(1)}>
              <span>{data.q2}</span>
              <span className="toggle-icon">
                {activeIndex === 1 ? '-' : '+'}
              </span>
            </div>
            {activeIndex === 1 && (
              <div className="faq-content">{data.ans2}</div>
            )}
          </div>

          {/* Third FAQ */}
          <div className={`faq-item ${activeIndex === 2 ? 'active' : ''}`}>
            <div className={`faq-title ${activeIndex === 2 ? 'active' : ''}`} onClick={() => toggleAccordion(2)}>
              <span>{data.q3}</span>
              <span className="toggle-icon">
                {activeIndex === 2 ? '-' : '+'}
              </span>
            </div>
            {activeIndex === 2 && (
              <div className="faq-content">{data.ans3}</div>
            )}
          </div>

          {/* Fourth FAQ */}
          <div className={`faq-item ${activeIndex === 3 ? 'active' : ''}`}>
            <div className={`faq-title ${activeIndex === 3 ? 'active' : ''}`} onClick={() => toggleAccordion(3)}>
              <span>{data.q4}</span>
              <span className="toggle-icon">
                {activeIndex === 3 ? '-' : '+'}
              </span>
            </div>
            {activeIndex === 3 && (
              <div className="faq-content">{data.ans4}</div>
            )}
          </div>
        </div>
    </div>
    
    <div className="" id="mzgc">
        <br /><br />
        <div className="about__thumb">
        <img src={logo} className="about__thumb-img" alt="" />
        </div>
    </div>
    <br /><br />
    </section>
    </>
  )
}
export default Faqs;