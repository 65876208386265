import { useState, useEffect } from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header_Dashboard from '../../layouts/Header_Dashboard';

const Login = (props) => {
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        document.title = `${props.project} | Member Login`;
    
        const loggedInStatus = localStorage.getItem('loggedInStatus');
        if (loggedInStatus === 'true') {
            // window.location.href = '/dashboard';
            window.location.href = `${window.location.origin}/dashboard`;
        }
    }, [props.project]);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('email', data.email);
        formData.append('password', data.password);

        axios.post(`${baseUrl}/login/`, formData)
            .then((response) => {
                if (response.data.bool === true) {
                    localStorage.setItem('loggedInStatus', true); // gets logged in status
                    localStorage.setItem('memberId', response.data.member_id); // gets user id
                    localStorage.setItem('fname', response.data.fname); // Save first name
                    localStorage.setItem('sname', response.data.sname); // Save last name
                    localStorage.setItem('isAdmin', response.data.is_admin);  // Save admin status
                    
                    // window.location.href = '/dashboard';
                    window.location.href = `${window.location.origin}/dashboard`;
                } else {
                    alert('Invalid credentials');
                }
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    const loggedInStatus = localStorage.getItem('loggedInStatus');
    if (loggedInStatus === 'true') {
        // window.location.href = '/dashboard';
        window.location.href = `${window.location.origin}/dashboard`;
    }

    return (
        <>
        <Header_Dashboard />

<div id="page-top">
<div id="wrapper">

    <div id="content-wrapper" className="d-flex flex-column">
        <div id="content" style={{ background: '#ddd'}}>
      {/* <ContentWrapper /> */}
      
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between my-4">
        </div>

        <form> 
            <div className="row">
                <div className="col-xl-8 col-lg-7 m-auto">
                    <div className="card shadow mb-4">
                        <div className="card-header bg-primary text-white py-3  flex-row align-items-center justify-content-between">
                            <p className="small float-right" style={{ color: 'white' }}>Not yet registered? &nbsp;
                            <Link to="/registration" className="btn btn-sm" style={{ color: 'black', background: '#FFDF00' }}>Register Here!</Link></p>
                            
                            <h1 className="font-weight-bold">Eagles of Zion</h1>
                            <h5 className="m-0 font-weight-bold">Member Login</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">                                        
                                    <div className="form-group row"> 
                                        <div className="col-12 mb-3">
                                            <h4 className="small font-weight-bold">Email Address</h4>
                                            <input name="email" onChange={handleChange} value={data.email} type="email" 
                                                className="form-control" maxLength="60" id="exampleInputEmail" 
                                                aria-describedby="emailHelp" placeholder="Enter Email Address..." required />
                                        </div>
                                    
                                        <div className="col-12 mb-3">
                                            <h4 className="small font-weight-bold">Password</h4>                                            
                                            <input name="password" onChange={handleChange} value={data.password} type="password" 
                                                className="form-control" maxLength="100" id="exampleInputPassword" 
                                                placeholder="Password" required />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <button type="submit" onClick={submitForm} className="btn btn-primary btn-user btn-block">
                                                Login
                                            </button>
                                        </div>
                                    </div>


                                        
                                    {/* <div className="col-sm-12 col-md-6 mb-3">
                                        <h4 className="small font-weight-bold">First Name</h4>
                                        <input name="fname" value={data.fname} onChange={handleChange} maxLength="40" 
                                            className="form-control" required />
                                    </div>

                                    <div className="col-sm-12 col-md-6 mb-3">
                                        <h4 className="small font-weight-bold">Email Address</h4>
                                        <input name="email" type="email" value={data.email} onChange={handleChange} maxLength="60" 
                                            className="form-control" required />
                                    </div>

                                    <div className="col-sm-12 col-md-6 mb-3">
                                        <h4 className="small font-weight-bold">Create a Password</h4>
                                        <input name="password" type="password" value={data.password} onChange={handleChange} maxLength="100" 
                                            className="form-control" placeholder="A secret code you can remember ..." required />
                                    </div> 

                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mb-3">
                                        <button type="submit" onClick={submitForm} className="btn btn-success btn-user btn-block">
                                            Register
                                        </button>
                                    </div>
                                    <hr />
                                    <div className="col-sm-12 col-md-6 mb-3">                                        
                                        <p className="small" style={{ color: 'black' }}>Already registered? &nbsp;
                                            <Link to="/login" className="btn btn-sm" style={{ color: 'black', background: '#FFDF00' }}>Login Here!</Link></p>
                                    </div> 
                                </div> */}
                              </div>
                          </div>
                      </div>
                  </div>                          
                </div> 
                </div>    
                </form>                    
            </div>
        </div>
        

      </div>
  </div>

  </div>
        </>
    )
}
export default Login;
