import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import { UilArrowRight } from '@iconscout/react-unicons';

const EaglesPresident_Welcome = () => {
  const slug = 'eagles-president-welcome';
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  return (
    <>
    <section className="welcome_eagles grid grey-bg" id="EaglesPresident_Welcome">
        <div className="about__data pl-2">
          <h2 className="section__title about__title">{data.title}</h2>
          <h1 className="office subtitle">{data.subtitle}</h1><br />
          
          <p className="about__description">
          {data.content}... Read more</p>
          {/* the earth - that whosoever believes in HIM shall not perish</p> */}

          <br />
          <span className="brand__button quicklinks">
            Read the Eagles' President Welcome Message <UilArrowRight className="brand__button-icon" />
          </span>

          <br /><br />
          <p><Link to="" className="button button__header">Social Icons</Link></p> 
        </div>

        
        <div className="welcome1_left full-image" id="mzgc">
          <div className="about__thumb">
            <img src={data.image} className="about__thumb-img" alt="" />

            <div>
                <h1 className="office">{data.person_name}</h1>
            </div>
            <div>
                <div className="office_title">
                  {data.person_office}
                </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default EaglesPresident_Welcome
