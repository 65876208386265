import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Swal from 'sweetalert2';

const CMS_UpcomingEvent = () => {
  const slug = 'upcoming-event';
  const [data, setData] = useState({
    section: '',
    title: '',
    subtitle: '',
    content: '',
    image_gallery: null,  // Initialize this as null for file upload
    existingImageUrl: ''  // This will hold the URL of the already uploaded image
  });

  useEffect(() => {
    axios.get(`${baseUrl}/cms/${slug}/`)
      .then(response => {
        console.log(response.data);
        setData({
          ...response.data,
          existingImageUrl: response.data.image_gallery  // Set existing image URL
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [slug]);

  const handleChange = (e) => {
    if (e.target.name === 'image_gallery') {
      setData({
        ...data,
        [e.target.name]: e.target.files[0]  // Use files[0] for the file input
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('section', data.section);
    formData.append('title', data.title);
    formData.append('subtitle', data.subtitle);
    formData.append('content', data.content);

    // Append image only if a new one is selected
    if (data.image_gallery) {
      formData.append('image', data.image_gallery);
    }

    axios.put(`${baseUrl}/cms/${slug}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'  // Required for file uploads
      }
    })
      .then((response) => {
        successAlert();
      })
      .catch(error => {
        errorAlert(error);
      });
  };

  const successAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Content updated successfully!",
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.href = '/content-management';
    });
  };

  const errorAlert = (error) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "An error occurred!",
      text: error.message,
      showConfirmButton: false,
      timer: 1500
    });
  };

  return (
    <>
    <div className="card shadow mb-4">
      <div className="card-header bg-danger text-white py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold">{data.section} (Content Management)</h6>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className="">
              <form className="user" onSubmit={submitForm} encType="multipart/form-data">

                <div className="form-group row">
                  <div className="col-12">
                    <h4 className="small font-weight-bold">Title of Section</h4>
                    <input name="title" value={data.title} onChange={handleChange} maxLength="255"
                      className="form-control" /><br />
                  </div>
                  <div className="col-12 mb-3 mb-sm-0">
                    <h4 className="small font-weight-bold">Subtitle</h4>
                    <input name="subtitle" value={data.subtitle} onChange={handleChange} maxLength="255"
                      className="form-control" /><br />
                  </div>
                  <div className="col-12">
                    <h4 className="small font-weight-bold">Content</h4>
                    <textarea name="content" value={data.content} onChange={handleChange}
                      className="form-control" rows={7}></textarea>
                  </div>
                  
                  <div className="col-12 my-3 mb-sm-0">
                      <img
                          src={data.image}  // No need to concatenate the URL
                          alt={data.name}
                          style={{ width: '200px', height: 'auto' }}
                      />
                  </div>
                  <div className="col-12 my-3 mb-sm-0">                        
                      <h4 className="small font-weight-bold">Current Image</h4>
                      {data.image}
                  </div>

                  <div className="col-12 my-3 mb-sm-0">
                    <h4 className="small font-weight-bold">Do you want to upload another image?</h4>

                    {data.existingImageUrl && (
                      <div>
                        <img src={data.existingImageUrl} alt="Existing" style={{ width: '100px', height: 'auto' }} />
                        <p>Current Image</p>
                      </div>
                    )}

                    <input
                      type="file"
                      name="image_gallery"
                      onChange={handleChange}
                      accept="image/*"
                    />
                    <br /><hr />
                  </div>
                  
                </div>

                <button type="submit" className="btn btn-primary btn-user">
                  Save Draft
                </button>
                <Link to="" className="btn btn-secondary btn-user mx-2">Preview</Link>

                <button type="" className="btn btn-success btn-user float-right">
                  PUBLISH AS FINAL
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CMS_UpcomingEvent;
