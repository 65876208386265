import { useEffect } from 'react';
import Sidebar from "../../layouts/Sidebar";
import Header_Dashboard from '../../layouts/Header_Dashboard';
// import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Logout_Modal from '../../layouts/Logout_Modal';
import PgContent_Dash from '../../layouts/PgContent_Dash';

const Dashboard = (props) => {
  useEffect(() => {
    const loggedInStatus = localStorage.getItem('loggedInStatus');
    if (loggedInStatus !== 'true') {
      window.location.href = '/login';
    }
  }, []);

  return (
    <>
      <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar project={props.project} />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    <PgContent_Dash />            
                </div>
        
                {/* <Footer_Dashboard /> */}
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  );
};

export default Dashboard;
