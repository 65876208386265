// import './Footer.css'; // Custom CSS for Footer styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h4>About Us</h4>
          <p>
            Our mission as Eagles of Zion is to further the Gospel of Jesus Christ to the earth - 
            that whosoever believes in HIM shall not perish... (John 3:16)
          </p>
        </div>

        <div className="footer-column">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/home">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/zones">Zones</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>Contact</h4>
          <p><u>Email</u>: <br />
          nationalyouthfellowshipmzgc<br />@gmail.com</p><br />

          <p>Phone: <br />
          +234 803-854-7692</p>
        </div>

        {/* Optional: Social Media Column */}
        <div className="footer-column">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="https://facebook.com"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
            <a href="https://instagram.com"><i className="fab fa-instagram"></i></a>
            <a href="https://linkedin.com"><i className="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Eagles of Zion. By <a href="">Website Team</a>.</p>
      </div>
    </footer>
  );
};

export default Footer;
